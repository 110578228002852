import React, { FC } from 'react';
import { Autocomplete, Button, Fieldset, Flex, PasswordInput, Select, TextInput } from '@mantine/core';
import { apiMethods, authTypes, useApiTestsStore } from '../ApiTestsStoreProvider';
import { CONSTANTS } from '../../../config';

export const AuthFieldsRender: FC = () => {
  const { apiTestForm } = useApiTestsStore();

  return (
    <Fieldset
      // legend="Url"
      styles={{
        root: {
          display: 'flex',
          flexDirection: 'row',
          gap: 0,
          padding: 0,
          border: 0,
        },
      }}
    >
      <Select
        size="xs"
        styles={{
          root: {
            width: 100,
          },
          input: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
        }}
        withAsterisk
        // label="Auth type"
        placeholder="Basic"
        data={authTypes}
        key={apiTestForm.key('credentials.authType')}
        {...apiTestForm.getInputProps('credentials.authType')}
      />
      {apiTestForm.values.credentials?.authType === 'Basic' ? (
        <>
          <Autocomplete
            size="xs"
            withAsterisk
            styles={{
              root: {
                width: 250,
              },
              input: {
                width: '100%',
                borderRadius: 0,
              },
            }}
            // label="Login:"
            placeholder="login"
            data={[]}
            key={apiTestForm.key('credentials.login')}
            {...apiTestForm.getInputProps('credentials.login')}
          />
          <PasswordInput
            size="xs"
            withAsterisk
            styles={{
              root: {
                flex: 1,
              },
              input: {
                width: '100%',
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
              },
            }}
            // label="Password:"
            placeholder="password"
            key={apiTestForm.key('credentials.password')}
            {...apiTestForm.getInputProps('credentials.password')}
          />
        </>
      ) : apiTestForm.values.credentials?.authType === 'Bearer' ? (
        <Autocomplete
          size="xs"
          withAsterisk
          styles={{
            root: {
              flex: 1,
            },
            input: {
              width: '100%',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
            },
          }}
          // label="Token:"
          placeholder="token"
          data={[]}
          key={apiTestForm.key('credentials.token')}
          {...apiTestForm.getInputProps('credentials.token')}
        />
      ) : (
        <></>
      )}
    </Fieldset>
  );
};
