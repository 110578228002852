import React from 'react';
import { Accordion, AppShell, NavLink, rem, ScrollArea } from '@mantine/core';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { IconApi, IconMessagePlus } from '@tabler/icons-react';
import { useAppTheme } from '../../themes';
import './assets/styles/naw.scss';
import { useAppLayout } from '../AppLayoutProvider';
import { FooterLayoutComponent } from '../FooterLayout';

export const NavbarLayout = () => {
  const { mantineTheme: theme, appColorScheme, isMobileLayout } = useAppTheme();
  const { toggleNavbar } = useAppLayout();

  return (
    <AppShell.Navbar
      style={{
        // borderLeft: 'calc(0.0625rem * var(--mantine-scale)) solid transparent',
        // borderRight: 'calc(0.0625rem * var(--mantine-scale)) solid transparent',
        borderLeft: 0,
        backgroundColor: appColorScheme === 'light' ? theme.colors.gray[1] : theme.colors.dark[5],
      }}
    >
      <AppShell.Section grow component={ScrollArea.Autosize} scrollbarSize={0}>
        <NavLink
          w="100%"
          to="/apitests"
          label="Api tests"
          component={RouterNavLink}
          leftSection={<IconApi />}
          variant="subtle"
          onClick={() => {
            toggleNavbar();
          }}
          styles={{ root: { padding: 'var(--mantine-spacing-sm) calc(var(--mantine-spacing-sm) + 3px)' } }}
        />
      </AppShell.Section>
      <AppShell.Section style={{ width: '100%' }}>
        {isMobileLayout && (
          <AppShell.Section
            w="100%"
            px="var(--mantine-spacing-xs)"
            py={rem(5)}
            h={50}
            style={{
              backgroundColor: theme.colors.egwPrimary[9],
              color: '#FFFFFF',
            }}
          >
            <FooterLayoutComponent />
          </AppShell.Section>
        )}
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
