import React from 'react';
import { JsonInput } from '@mantine/core';
import { bodySupportMethods, useApiTestsStore } from '../ApiTestsStoreProvider';

export const RequestBodyFieldRender: React.FC = () => {
  const { apiTestForm } = useApiTestsStore();

  if (bodySupportMethods.includes(apiTestForm.values.apiMethod)) {
    return (
      <JsonInput
        // size="xs"
        label="Body Json:"
        placeholder="please input"
        key={apiTestForm.key('requestBody')}
        {...apiTestForm.getInputProps('requestBody')}
        validationError="Invalid JSON"
        formatOnBlur
        styles={{
          root: {
            display: 'flex',
            flexDirection: 'row',
            // flexWrap: 'nowrap',
            gap: 5,
            // flex: 1,
            minWidth: 150,
            height: 200,
          },
          wrapper: {
            flex: 1,
            height: '100%',
          },
          input: {
            height: '100%',
          },
        }}
        // minRows={9}
      />
    );
  }
  return null;
};
