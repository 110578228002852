import React, { FC, PropsWithChildren } from 'react';
import { AppShell } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { HeaderLayout } from './HeaderLayout';
import { FooterLayout } from './FooterLayout';
import { AppLayoutProvider, useAppLayout } from './AppLayoutProvider';
import { useAppTheme } from '../themes';

const AppLayoutComponent: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { openedNavbar, withNavBar } = useAppLayout();
  const { isMobileLayout, isTargetWidthLayout } = useAppTheme();

  return (
    <AppShell
      // padding="md"
      navbar={
        withNavBar
          ? {
              width: isMobileLayout || isTargetWidthLayout ? 0 : 200,
              breakpoint: 'sm',
              collapsed: { mobile: !openedNavbar },
            }
          : undefined
      }
    >
      <HeaderLayout isAuthenticated />
      <Outlet />
      {!isMobileLayout && <FooterLayout />}
    </AppShell>
  );
};

export const AppLayout: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <AppLayoutProvider>
      <AppLayoutComponent>{children}</AppLayoutComponent>
    </AppLayoutProvider>
  );
};
