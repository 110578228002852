import React from 'react';
import { Burger } from '@mantine/core';
import { useAppLayout } from '../AppLayoutProvider';
import { useAppTheme } from '../../themes';

export const NavbarBurger = () => {
  const { isMobileLayout } = useAppTheme();
  const { openedNavbar, toggleNavbar, withNavBar } = useAppLayout();
  const label = !openedNavbar ? 'Close navigation' : 'Open navigation';
  if (!withNavBar) {
    return null;
  }
  return (
    <Burger
      size="sm"
      color="white"
      opened={openedNavbar}
      onClick={() => toggleNavbar()}
      aria-label={label}
      hiddenFrom={isMobileLayout ? undefined : 'sm'}
    />
  );
};
