import React from 'react';
import { Autocomplete, Button, Flex, Select } from '@mantine/core';
import { apiMethods, useApiTestsStore } from '../ApiTestsStoreProvider';
import { RequestBodyFieldRender } from './RequestBodyFieldRender';
import { AuthFieldsRender } from './AuthFieldsRender';
import { CONSTANTS } from '../../../config';
import { UrlFieldsRender } from './UrlFieldsRender';

export const ApiTestsForm: React.FC = () => {
  const { apiTestForm, onApiTestFormSubmit } = useApiTestsStore();
  return (
    <form onSubmit={apiTestForm.onSubmit((values) => onApiTestFormSubmit())}>
      <Flex direction="column" gap="md">
        <UrlFieldsRender />
        <AuthFieldsRender />
        <RequestBodyFieldRender />
      </Flex>
    </form>
  );
};
