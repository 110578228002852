import { Navigate, RouteObject } from 'react-router-dom';
import React from 'react';
import { AppLayout, MainLayout } from '../layout';
import { HomePage, ApiTestsPage } from '../pages';
import { SecuredRoute } from './SecuredRoute';

export const ROUTES_MAP: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        id: 'SecuredRoute',
        path: '/',
        element: (
          <SecuredRoute>
            <MainLayout />
          </SecuredRoute>
        ),
        children: [
          {
            id: 'Home',
            path: '',
            element: <HomePage />,
          },
          {
            id: 'ApiTests',
            path: 'apitests',
            element: <ApiTestsPage />,
          },
          {
            path: '*',
            element: <Navigate to="/" replace />,
          },
        ],
      },

      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
];
