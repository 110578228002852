import React, { FC } from 'react';
import { Autocomplete, Button, Fieldset, Flex, Paper, PasswordInput, Select, TextInput } from '@mantine/core';
import { apiMethods, authTypes, useApiTestsStore } from '../ApiTestsStoreProvider';
import { CONSTANTS } from '../../../config';

export const UrlFieldsRender: FC = () => {
  const { apiTestForm, isLoading } = useApiTestsStore();

  return (
    <Fieldset
      // legend="Url"
      styles={{
        root: {
          display: 'flex',
          flexDirection: 'row',
          gap: 0,
          padding: 0,
          border: 0,
        },
      }}
    >
      <Select
        size="xs"
        styles={{
          root: {
            width: 100,
          },
          input: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          },
        }}
        withAsterisk
        // label="Method"
        placeholder="GET"
        data={apiMethods}
        key={apiTestForm.key('apiMethod')}
        {...apiTestForm.getInputProps('apiMethod')}
      />
      <Autocomplete
        size="xs"
        withAsterisk
        data={CONSTANTS.APP_API_TESTS_BASEURLS}
        styles={{
          root: {
            width: 250,
          },
          input: {
            width: '100%',
            borderRadius: 0,
          },
        }}
        placeholder="utp_sec-test/hs/zoho/********"
        key={apiTestForm.key('apiBaseUrl')}
        {...apiTestForm.getInputProps('apiBaseUrl')}
      />
      <Autocomplete
        size="xs"
        withAsterisk
        styles={{
          root: {
            flex: 1,
          },
          input: {
            width: '100%',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            borderRadius: 0,
          },
        }}
        data={CONSTANTS.APP_API_TESTS_PATCHES?.[apiTestForm.values.apiMethod]}
        placeholder="utp_sec-test/hs/zoho/********"
        key={apiTestForm.key('apiPath')}
        {...apiTestForm.getInputProps('apiPath')}
      />
      <Button
        type="submit"
        size="xs"
        loading={isLoading}
        styles={{
          root: {
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          },
        }}
      >
        Send
      </Button>
    </Fieldset>
  );
};
