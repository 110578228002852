import React from 'react';
import { Flex, Title } from '@mantine/core';

export const TitleSection = () => {
  return (
    <Flex gap={1} h="100%" justify="flex-start" align="center" direction="row" wrap="nowrap">
      {/* <Title */}
      {/*  size="sm" */}
      {/*  order={4} */}
      {/*  styles={{ */}
      {/*    root: { */}
      {/*      fontFamily: 'Inter, sans-serif', */}
      {/*      fontWeight: 400, */}
      {/*      // fontSize: '1em', */}
      {/*      color: 'white', */}
      {/*      whiteSpace: 'nowrap', */}
      {/*      textOverflow: 'ellipsis', */}
      {/*    }, */}
      {/*  }} */}
      {/*  visibleFrom="sm" */}
      {/* > */}
      {/*  Test Tools */}
      {/* </Title> */}
    </Flex>
  );
};
