import React from 'react';
import { Box, Button, Flex, JsonInput, Loader, LoadingOverlay, Paper, ScrollArea, Textarea } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ApiTestsStoreProvider, useApiTestsStore } from './ApiTestsStoreProvider';
import { useTitle } from '../../hooks/useTitle';
import { ApiTestsForm } from './ApiTestForm';

export const ApiTestsComponent = () => {
  useTitle('Api Tests');
  const { response, isLoading } = useApiTestsStore();
  const { ref: viewportRef, height: viewportHeight } = useElementSize();
  return (
    <Flex h="100%" direction="column" gap="md">
      <ApiTestsForm />
      <Paper pos="relative" withBorder component={ScrollArea} shadow="xs" flex={1} viewportRef={viewportRef}>
        <LoadingOverlay visible={isLoading} loaderProps={{ children: <Loader size={50} /> }} />
        <JsonInput
          size="xs"
          value={JSON.stringify(response, undefined, 2)}
          h={viewportHeight}
          styles={{
            root: {
              display: 'flex',
              flexDirection: 'row',
              // flexWrap: 'nowrap',
              gap: 5,
              height: '100%',
              minWidth: 150,
            },
            wrapper: {
              flex: 1,
            },
            input: {
              height: '100%',
              opacity: 1,
              color: 'var(--input-color)',
              cursor: 'text',
            },
          }}
          disabled
        />
      </Paper>
    </Flex>
  );
};

export const ApiTestsPage = () => {
  return (
    <ApiTestsStoreProvider>
      <ApiTestsComponent />
    </ApiTestsStoreProvider>
  );
};
