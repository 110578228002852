import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';
import { NavbarLayout } from './NavbarLayout';

const MainLayoutComponent = () => {
  return (
    <>
      <NavbarLayout />
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </>
  );
};

export const MainLayout = () => {
  return <MainLayoutComponent />;
};
