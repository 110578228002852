import { ApiPathesType, AuthCredentials } from '../pages/ApiTestsPage/ApiTestsStoreProvider';

export interface IConstants {
  APP_TITLE: string;
  APP_COPYRIGHT: string;
  APP_ROOT_URL: string;
  APP_ROOT_URL_ENDPOINT: string;
  APP_WITH_HASH_ROUTER: boolean;
  APP_API_TESTS_PATCHES?: ApiPathesType;
  APP_API_TESTS_BASEURLS: string[];
  APP_API_TESTS_CREDENTIALS: AuthCredentials;
}

export type ReactAppConfigType = Partial<{
  REACT_APP_TITLE: string;
  REACT_APP_COPYRIGHT: string;
  REACT_APP_ROOT_URL: string;
  REACT_APP_ROOT_URL_ENDPOINT: string;
  REACT_APP_WITH_HASH_ROUTER: boolean;
  REACT_APP_API_TESTS_PATCHES?: ApiPathesType;
  REACT_APP_API_TESTS_BASEURLS?: string[];
  REACT_APP_API_TESTS_CREDENTIALS?: AuthCredentials;
}>;

declare global {
  interface Window {
    REACT_APP_CONFIG?: ReactAppConfigType;
  }
}

window.REACT_APP_CONFIG ??= {};
export const CONSTANTS: IConstants = {
  APP_TITLE: window.REACT_APP_CONFIG.REACT_APP_TITLE ?? process.env.REACT_APP_TITLE ?? 'React App',
  APP_COPYRIGHT: window.REACT_APP_CONFIG.REACT_APP_COPYRIGHT ?? process.env.REACT_APP_COPYRIGHT ?? 'Todo',
  APP_ROOT_URL:
    window.REACT_APP_CONFIG.REACT_APP_ROOT_URL ??
    process.env.REACT_APP_ROOT_URL ??
    `${window.location.protocol}//${window.location.host}`,
  APP_ROOT_URL_ENDPOINT:
    window.REACT_APP_CONFIG.REACT_APP_ROOT_URL_ENDPOINT ?? process.env.REACT_APP_ROOT_URL_ENDPOINT ?? '',
  APP_WITH_HASH_ROUTER:
    window.REACT_APP_CONFIG.REACT_APP_WITH_HASH_ROUTER ?? process.env.REACT_APP_WITH_HASH_ROUTER === 'true',
  APP_API_TESTS_PATCHES: window.REACT_APP_CONFIG?.REACT_APP_API_TESTS_PATCHES ?? {
    GET: ['/zoho/Zakaz/%D0%A6%D0%A8%D0%9E00000239', 'zohoapi'],
    POST: ['/zoho/Order/json', 'zohoapi'],
  },
  APP_API_TESTS_BASEURLS: window.REACT_APP_CONFIG?.REACT_APP_API_TESTS_BASEURLS ?? [
    'https://basapi.intelltexcorp.com/utp_sec/hs',
    'https://basapi.intelltexcorp.com/utp_sec-test/hs',
    'https://basapi.intelltexcorp.com/rrev4ge-test-db/hs',
  ],
  APP_API_TESTS_CREDENTIALS: window.REACT_APP_CONFIG?.REACT_APP_API_TESTS_CREDENTIALS ?? {
    authType: 'Basic',
    login: 'Админ',
    password: '132',
  },
};
