import React from 'react';
import { ActionIcon, Avatar, Menu } from '@mantine/core';
import { IconKey, IconLogout, IconUserEdit } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';

export const LoginSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Menu shadow="md" width={200} disabled>
      {/* <Menu.Target> */}
      {/*  <ActionIcon */}
      {/*    variant="subtle" */}
      {/*    radius="xl" */}
      {/*    disabled={!user} */}
      {/*    styles={{ */}
      {/*      root: { */}
      {/*        borderColor: 'transparent', */}
      {/*        backgroundColor: 'transparent', */}
      {/*      }, */}
      {/*    }} */}
      {/*  > */}
      {/*    <Avatar radius="xl" size={28} src={user?.profile?.picture} alt="it's me" /> */}
      {/*  </ActionIcon> */}
      {/* </Menu.Target> */}
      {/* <Menu.Dropdown> */}
      {/*  <Menu.Label>{user?.profile?.name}</Menu.Label> */}
      {/*  <Menu.Item */}
      {/*    onClick={() => { */}
      {/*      const url = `/profile`; */}
      {/*      window.open(url, '_blanc'); */}
      {/*    }} */}
      {/*    leftSection={<IconUserEdit size="1rem" stroke={2} />} */}
      {/*  > */}
      {/*    Profile */}
      {/*  </Menu.Item> */}
      {/*  <Menu.Item */}
      {/*    onClick={() => { */}
      {/*      navigate('/auth', { state: { preAuthLocation: { ...location } } }); */}
      {/*    }} */}
      {/*    leftSection={<IconKey size="1rem" stroke={2} />} */}
      {/*  > */}
      {/*    Auth Page */}
      {/*  </Menu.Item> */}
      {/*  <Menu.Divider /> */}
      {/*  <Menu.Item */}
      {/*    color="red" */}
      {/*    onClick={() => { */}
      {/*      void removeUser(); */}
      {/*    }} */}
      {/*    leftSection={<IconLogout size="1rem" stroke={2} />} */}
      {/*  > */}
      {/*    Log Out */}
      {/*  </Menu.Item> */}
      {/* </Menu.Dropdown> */}
    </Menu>
  );
};
