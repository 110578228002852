import React from 'react';
import { Flex, Text, ActionIcon } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconMail } from '@tabler/icons-react';
import { useAppTheme } from '../../themes';

export const ContactUsSection = () => {
  const { isMobileLayout, mantineTheme: theme } = useAppTheme();
  const iconColor = theme.colors.gray[0];
  return (
    <Flex gap={5} justify="flex-end" align="center" direction="row" wrap="nowrap">
      {!isMobileLayout && (
        <Text component={Link} to="/" style={{ alignSelf: 'end' }}>
          Contact
        </Text>
      )}
      <ActionIcon
        variant="transparent"
        component={Link}
        to="mailto:someone@yoursite.com"
        // style={{ alignSelf: 'start' }}
      >
        <IconMail color={iconColor} />
      </ActionIcon>
    </Flex>
  );
};
