import React from 'react';
import { Flex, Group } from '@mantine/core';
import { LoginSection } from './LoginSection';
import { AppThemeSwitcher, useAppTheme } from '../../themes';

export interface IAppSettingsSectionProps {
  isAuthenticated?: boolean;
}
export const AppSettingsSection = (props: IAppSettingsSectionProps) => {
  const { isAuthenticated = false } = props;
  const { isMobileLayout, isTargetWidthLayout } = useAppTheme();
  return (
    <Flex h="100%" gap="var(--mantine-spacing-xs)" justify="space-between" align="center" direction="row" wrap="nowrap">
      <AppThemeSwitcher />
      {isAuthenticated && <LoginSection />}
    </Flex>
  );
};
