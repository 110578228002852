import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { SwitchRoutes } from './SwitchRoutes';
import { CONSTANTS } from '../config';

export const RootRouter = ({ ...rest }): React.ReactElement => {
  const Router = CONSTANTS.APP_WITH_HASH_ROUTER ? HashRouter : BrowserRouter;
  return (
    <Router>
      <SwitchRoutes />
    </Router>
  );
};
