import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';

export interface IUseAppLayoutResult {
  openedNavbar: boolean;
  withNavBar: boolean;
  toggleNavbar: () => void;
}

export const AppLayoutContext = createContext<IUseAppLayoutResult | undefined>(undefined);
AppLayoutContext.displayName = 'AppLayoutContext';

export const useAppLayout = (): IUseAppLayoutResult => {
  const context = useContext(AppLayoutContext);
  if (!context) {
    console.warn('useLayoutStoreContext must be used within LayoutStoreProvider');
  }
  return context as IUseAppLayoutResult;
};

export const AppLayoutProvider = ({ children }) => {
  const location = useLocation();
  const [openedNavbar, { toggle: toggleNavbar }] = useDisclosure(false);
  const [withNavBar, setWithNavBar] = useState(location.pathname !== '/auth');

  useEffect(() => {
    setWithNavBar(location.pathname !== '/auth');
  }, [location.pathname]);

  const appLayoutContextObject: IUseAppLayoutResult = useMemo(
    () => ({
      openedNavbar,
      toggleNavbar,
      withNavBar,
    }),
    [openedNavbar, withNavBar],
  );

  return <AppLayoutContext.Provider value={appLayoutContextObject}>{children}</AppLayoutContext.Provider>;
};
