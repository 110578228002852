import React from 'react';
import { AppShell, Flex } from '@mantine/core';
import { AppLogoSection } from './AppLogoSection';
import { AppSettingsSection } from './AppSettingsSection';
import { TitleSection } from './TitleSection';
import { NavbarBurger } from '../NavbarLayout';
import { useAppTheme } from '../../themes';

export interface IHeaderLayoutProps {
  isAuthenticated?: boolean;
}

export const HeaderLayout = (props: IHeaderLayoutProps) => {
  const { isAuthenticated = false } = props;
  const { mantineTheme: theme } = useAppTheme();

  return (
    <AppShell.Header
      w="100%"
      px="var(--mantine-spacing-xs)"
      style={{
        backgroundColor: theme.colors.egwPrimary[9],
        zIndex: 200,
      }}
    >
      <Flex h="100%" gap={3} justify="space-between" align="center" direction="row" wrap="nowrap">
        <Flex
          gap="var(--mantine-spacing-xs)"
          h="100%"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          {isAuthenticated && <NavbarBurger />}
          <AppLogoSection />
        </Flex>
        <TitleSection />
        <AppSettingsSection isAuthenticated={isAuthenticated} />
      </Flex>
    </AppShell.Header>
  );
};
