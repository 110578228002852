import React from 'react';
import { Flex, Image, Text } from '@mantine/core';
import { useAppTheme } from '../../themes';
import appLogo from '../../assets/images/IntellTex_logo.png';

export const CopyrightSection = () => {
  const now = new Date();
  const { isMobileLayout } = useAppTheme();
  return (
    <Flex h="100%" gap={3} justify="flex-start" align="center" direction="row" wrap="nowrap">
      <Flex h="100%" justify="center" align="center" direction="column" wrap="nowrap">
        <Image src={appLogo} h="xl" />
      </Flex>
      <Flex h="100%" justify="center" align="flex-start" direction="column" wrap="nowrap">
        <Text span lineClamp={1} size="copyrights">
          Copyright, © {now.getFullYear()}. TODO.{/* <br /> */}
        </Text>
        <Text span size="copyrights" lineClamp={isMobileLayout ? 2 : 1}>
          Address TODO.
        </Text>
      </Flex>
    </Flex>
  );
};
