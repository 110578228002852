import React, { createContext, useContext, useMemo, useState } from 'react';
import { useForm, UseFormReturnType } from '@mantine/form';
import axios, { AxiosError } from 'axios';
import { CONSTANTS } from '../../config';

export type ApiMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type AuthType = 'Basic' | 'Bearer' | 'None';

export const apiMethods: ApiMethodType[] = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
export const bodySupportMethods: ApiMethodType[] = ['POST', 'PUT', 'PATCH'];
export const authTypes: AuthType[] = ['Basic', 'Bearer', 'None'];

export type ApiPathesType = {
  [key in ApiMethodType]?: string[];
};

export type BasicCredentialsType = {
  login?: string;
  password?: string;
};

export type BearerCredentialsType = {
  token?: string;
};

export type AuthCredentials =
  | ({
      authType: 'Basic';
    } & BasicCredentialsType)
  | ({
      authType: 'Bearer';
    } & BearerCredentialsType)
  | { authType: 'None' };

export type ApiTestFormType = {
  apiMethod: ApiMethodType;
  apiPath: string;
  requestBody?: string;
  apiBaseUrl: string;
  credentials: AuthCredentials;
};

export interface IUseApiTestsStoreResult {
  response: string | object | object[] | null;
  apiTestForm: UseFormReturnType<ApiTestFormType>;
  onApiTestFormSubmit: () => Promise<void>;
  isLoading: boolean;
}

export const ApiTestsStoreContext = createContext<IUseApiTestsStoreResult | undefined>(undefined);
ApiTestsStoreContext.displayName = 'ApiTestsStoreContext';

export const useApiTestsStore = (): IUseApiTestsStoreResult => {
  const context = useContext(ApiTestsStoreContext);
  if (!context) {
    console.warn('useApiTestsStoreContext must be used within ApiTestsStoreProvider');
  }
  return context as IUseApiTestsStoreResult;
};

export const ApiTestsStoreProvider = ({ children }) => {
  const [response, setResponse] = useState<string | object | null>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const apiTestForm = useForm<ApiTestFormType>({
    name: 'ApiTestForm',
    initialValues: {
      apiMethod: 'GET',
      apiPath: CONSTANTS.APP_API_TESTS_PATCHES?.GET?.[0] ?? '',
      apiBaseUrl: CONSTANTS.APP_API_TESTS_BASEURLS?.[0],
      credentials: CONSTANTS.APP_API_TESTS_CREDENTIALS,
    },
    validate: { apiMethod: (value) => (value ? undefined : 'apiMethod eror') },
    validateInputOnBlur: false,
    onValuesChange: (current, previous) => {
      console.log({ current, previous });
      if (current.apiMethod !== previous.apiMethod) {
        apiTestForm.setFieldValue('apiPath', CONSTANTS.APP_API_TESTS_PATCHES?.[current.apiMethod]?.[0] || '');
        apiTestForm.setFieldValue('requestBody', '');
      }
      if (current.credentials.authType !== previous.credentials.authType) {
        apiTestForm.setFieldValue('credentials', { authType: current.credentials.authType });
      }
    },
  });

  const onApiTestFormSubmit = async () => {
    if (apiTestForm.validate().hasErrors) {
      return;
    }
    setIsLoading(true);
    try {
      const authorization =
        apiTestForm.values.credentials?.authType === 'Basic'
          ? `Basic ${btoa(
              unescape(
                encodeURIComponent(
                  `${apiTestForm.values?.credentials?.login}:${apiTestForm.values?.credentials?.password}`,
                ),
              ),
            )}`
          : apiTestForm.values.credentials?.authType === 'Bearer'
          ? `Bearer ${apiTestForm.values?.credentials?.token}`
          : undefined;
      const response = await axios.request({
        baseURL: apiTestForm.values.apiBaseUrl,
        url: apiTestForm.values.apiPath,
        method: apiTestForm.values.apiMethod,
        //  mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorization,
        },
        ...(apiTestForm.values?.requestBody ? { data: apiTestForm.values.requestBody } : {}),
        // transformResponse: (r) => r,
      });
      console.log({ response });
      setResponse(() => {
        // if (response?.data) {
        //   return { data: response.data, status: response.status, statusText: response.statusText };
        // }
        // return response;
        return { data: response?.data, status: response?.status, statusText: response?.statusText };
      });
      setIsLoading(false);
    } catch (error) {
      // setResponse((error as AxiosError)?.response?.data ?? (error as AxiosError)?.message);
      setResponse({
        error: {
          data: (error as AxiosError)?.response?.data,
          status: (error as AxiosError)?.response?.status ?? (error as AxiosError)?.status,
          statusText: (error as AxiosError)?.response?.statusText ?? (error as AxiosError)?.status,
          message: (error as AxiosError)?.message,
        },
      });
      console.error({ error });
      setIsLoading(false);
    }
  };

  const apiTestsStoreContextObject: IUseApiTestsStoreResult = useMemo(
    () => ({
      apiTestForm,
      response,
      onApiTestFormSubmit,
      isLoading,
    }),
    [apiTestForm, response, isLoading],
  );

  return <ApiTestsStoreContext.Provider value={apiTestsStoreContextObject}>{children}</ApiTestsStoreContext.Provider>;
};
