import React from 'react';
import { AppShell, Flex, MantineProvider } from '@mantine/core';
import { CopyrightSection } from './CopyrightSection';
import { ContactUsSection } from './ContactUsSection';
import { useAppTheme } from '../../themes';

export const FooterLayoutComponent = () => {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      <Flex h="100%" w="100%" gap="sm" justify="space-between" align="center" direction="row" wrap="nowrap">
        <CopyrightSection />
        <ContactUsSection />
      </Flex>
    </MantineProvider>
  );
};
export const FooterLayout = () => {
  const { mantineTheme: theme } = useAppTheme();

  return (
    <AppShell.Footer
      style={{
        backgroundColor: theme.colors.egwPrimary[9],
        color: '#FFFFFF',
        paddingLeft: 'var(--mantine-spacing-xs)',
        paddingRight: 'var(--mantine-spacing-xs)',
        zIndex: 200,
      }}
    >
      <FooterLayoutComponent />
    </AppShell.Footer>
  );
};
