import React from 'react';
import { Text, Box, rem, Image } from '@mantine/core';
import { Link } from 'react-router-dom';
import appLogo from '../../assets/images/IntellTex_logo.png';

export const AppLogoSection = () => {
  return (
    <Box
      component={Link}
      to="/"
      // h="100%"
      style={{
        textDecoration: 'none',
        alignSelf: 'center',
        alignItems: 'baseline',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        gap: 5,
      }}
    >
      <Image src={appLogo} h="xl" />
      <Text
        component="span"
        size="appTitle"
        styles={{
          root: {
            fontFamily: 'Force Runner Title, sans-serif',
            fontWeight: 200,
            color: 'white',
            lineHeight: 0,
          },
        }}
      >
        Test Tools
      </Text>
    </Box>
  );
};
